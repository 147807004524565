import { useQuery } from "@tanstack/react-query";
import { signedApiV4Request, signedRequest } from "./api";
import { ONE_DAY, QueryKeyConstants } from "utils/constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext, useMemo } from "react";
import { GlobalContext } from "contextStates/Global";
import {
  getEngagementsQueryKey,
  getQueryKeysWithPartialKeys,
  handleCommentOptimisticUpdates,
  handlePostToFeedOptimisticUpdates,
  handleRepostActivityGroupOptimisticUpdates,
  handleRepostToFeedOptimisticUpdates,
} from "components/ActivityEngagements/utils";
import { pickBy } from "lodash";
import { logSentryEvent } from "utils/sentry";
import { produce } from "immer";
import { AuthContext } from "contextStates/AuthContext";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getFeedData } from "components/Feed/feed_apis";

export const fetchTrendingActivities = async () => {
  const resp = await signedRequest({
    method: "get",
    path: "/api/v4/get_trending_activities",
  });

  return resp.data.data;
};
export const fetchTrendingReferences = async (tokenId) => {
  const resp = await signedRequest({
    method: "get",
    path: `api/v4/get_trending_item_references?trending_group_id=${tokenId}`,
  });

  return { activities: resp.data.data };
};

export const fetchGlobalFeedReferences = async (tokenId) => {
  const resp = await signedRequest({
    method: "get",
    path: `api/v4/get_feed_item_references?trending_group_id=${tokenId}`,
  });

  return resp.data.data;
};

export const useFetchTrendingActivities = () => {
  const { data: trendingActivities } = useQuery({
    queryKey: ["trendingActivities"],
    queryFn: fetchTrendingActivities,
    retry: 3,
    retryOnMount: false,
    staleTime: 1000 * 60 * 5,
  });

  return { trendingActivities: trendingActivities || [] };
};

export const useFetchTrendingReferences = ({ tokenId, forGlobalFeed }) => {
  const fetcher = forGlobalFeed
    ? fetchGlobalFeedReferences
    : fetchTrendingReferences;
  const { data: trendingReferences, isLoading } = useQuery({
    queryKey: ["trendingReferences", tokenId],
    queryFn: () => fetcher(tokenId),
    retry: 3,
    retryOnMount: false,
    staleTime: 1000 * 60 * 5,
    enabled: !!tokenId,
  });

  return { trendingReferences: trendingReferences || {}, isLoading };
};

export const getGroupedActivity = async ({ id, type }) => {
  if (type == null || id == null) {
    return;
  }
  if (type === "post") {
    return await getPostDetails(id);
  }
  const response = await signedRequest({
    method: "post",
    path: "/api/v4/get_feed_item",
    bodyText: JSON.stringify({ id, type }),
  });

  return response.data.data;
};
export const getGroupedActivityFromBlob = async ({ id, type }) => {
  if (type == null || id == null) {
    return;
  }
  if (type === "post") {
    return await getPostDetails(id);
  }
  const response = await signedRequest({
    method: "post",
    path: "/api/v4/get_feed_item_from_blob",
    bodyText: JSON.stringify({ id, type }),
  });
  if (response.data.version === 0) {
    return response.data.data;
  }
  return transformSingleNewFeedItem({ feedResponse: response });
};
export const getPostDetails = async (id) => {
  const response = await signedRequest({
    method: "get",
    path: `/api/0xppl/post?id=${id}`,
  });

  return response.data.data;
};

export const getActivityById = async ({ activityId }) => {
  const response = await signedRequest({
    method: "post",
    path: "/api/v4/get_activity_by_id",
    bodyText: JSON.stringify({ id: activityId }),
  });

  return response.data.data;
};

export const fetchActivityByTxnHash = async (tx_hash, chain_id) => {
  const response = await signedApiV4Request({
    method: "post",
    path: "get_activity_by_txn_id",
    bodyText: JSON.stringify({
      tx_id: tx_hash,
      chain_id,
      legacy: false,
    }),
  });

  return response.data.data;
};

export const crossPostComment = async ({
  post_id,
  type,
  contents,
  urls,
  images,
  selectedPlatforms,
}) => {
  const payload = {
    post_id,
    type,
    contents,
    urls,
    images,
    platforms: selectedPlatforms,
  };

  const response = await signedRequest({
    method: "post",
    path: "/api/0xppl/create_cross_post_from_native_post",
    bodyText: JSON.stringify(payload),
  });
  return response.data.data;
};

export const getTxnTraces = async ({ txnHash }) => {
  return signedRequest({
    method: "post",
    path: "/api/v4/get_txn_traces",
    bodyText: JSON.stringify({
      tx_id: txnHash,
      legacy: false,
    }),
  });
};

export const getTxnTokenTransfers = async ({ activityData }) => {
  const activity = activityData?.activities[0];
  const txnId = activity?.transactions ? activity.transactions[0]?.tx_id : null;
  const chainId = activity?.transactions
    ? activity.transactions[0]?.chain_id
    : null;
  return signedRequest({
    method: "post",
    path: "/api/v4/get_txn_token_transfers",
    bodyText: JSON.stringify({
      tx_id: txnId,
      chain_id: chainId,
      legacy: false,
    }),
  });
};

export const fetchTxnTraces = async ({
  queryClient,
  activityData,
  txnHash,
}) => {
  if (activityData?.page_type === "transaction" || !txnHash) {
    return;
  }
  return await queryClient.fetchQuery({
    queryKey: [QueryKeyConstants.TXN_TRACES, txnHash],
    queryFn: () => getTxnTraces({ txnHash }),
  });
};

export const usePostMutation = ({ setIsSuccess, setIsError, onSuccess }) => {
  const postMutation = useCallback(
    async ({
      threads,
      selectedPlatforms,
      channelId,
      isRepost,
      repostId,
      repostType,
      scheduledDate,
      draft,
      pendingPostId,
      editorRawContent,
    }) => {
      const payload = {
        thread: threads,
        platforms: selectedPlatforms,
      };
      if (channelId) {
        payload.platforms_channel = { farcaster: channelId };
      }
      if (isRepost) {
        payload.repost_item_id = repostId;
        payload.repost_item_type = repostType;
      }
      if (scheduledDate) {
        payload.scheduled_time = Math.floor(scheduledDate.getTime() / 1000);
      }
      if (draft) {
        payload.draft = true;
      }
      if (pendingPostId) {
        payload.pending_post_id = pendingPostId;
      }
      if ((draft || scheduledDate) && editorRawContent) {
        payload.raw_data = editorRawContent;
      }
      const response = await signedRequest({
        method: "post",
        path: `/api/0xppl/create_post_thread`,
        bodyText: JSON.stringify(payload),
      });

      return response.data;
    },
    []
  );

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postMutation,
    mutationKey: "post_mutation", // choose a suitable key
    onSuccess: (data) => {
      const matchedQueryKeys = getQueryKeysWithPartialKeys({
        queryClient,
        queryKeys: [
          ["my_feed", "global_feed"],
          ["my_feed", "new_feed"],
        ],
      });
      if (matchedQueryKeys?.length && data.data?.type)
        matchedQueryKeys.forEach((queryKey) =>
          handlePostToFeedOptimisticUpdates({
            queryClient,
            queryKey,
            newData: data?.data,
          })
        );
      setIsSuccess(true);
      queryClient.refetchQueries({ queryKey: ["pendingPosts"] });
      if (onSuccess) onSuccess(data);
    },
    onError: () => setIsError(true),
  });
};
export const useDeletePostMutation = ({ onSuccess, onError }) => {
  const postMutation = async ({ postId }) => {
    const response = await signedRequest({
      method: "post",
      path: `/api/0xppl/delete_post`,
      bodyText: JSON.stringify({ id: postId }),
    });

    return response.data;
  };

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postMutation,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["my_feed"] });
      onSuccess();
    },
    onError,
  });
};

export const useReportPostMutation = ({ onSuccess, onError }) => {
  const reportPostMutation = async ({ postId, reason }) => {
    const response = await signedRequest({
      method: "post",
      path: `/api/0xppl/report_post`,
      bodyText: JSON.stringify({ post_id: postId, reason }),
    });

    return response.data;
  };

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: reportPostMutation,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["my_feed"] });
      onSuccess?.();
    },
    onError,
  });
};

export const useBoostPostMutation = ({ onSuccess, onError }) => {
  const boostPostMutation = async ({ id, type, reason }) => {
    const params = { id, type, reason };
    const response = await signedRequest({
      method: "post",
      path: `/api/0xppl/boost_post`,
      bodyText: JSON.stringify({ ...params }),
    });

    return response.data;
  };

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: boostPostMutation,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["my_feed"] });
      onSuccess?.();
    },
    onError,
  });
};

export const useNewCommentMutation = ({
  contentId,
  type,
  setIsError,
  setIsSuccess,
  onSuccess,
  queryKeys,
}) => {
  const { handleErrorSnackbar } = useContext(GlobalContext);

  const commentMutation = useCallback(
    async ({ contents, urls, images, selectedPlatforms, channelId }) => {
      const payload = {
        id: contentId,
        type,
        contents,
        urls,
        images,
        platforms: selectedPlatforms,
      };
      if (channelId) {
        payload.platforms_channel = { farcaster: channelId };
      }
      const response = await signedRequest({
        method: "post",
        path: "/api/0xppl/create_reply",
        bodyText: JSON.stringify(payload),
      });

      return response.data;
    },
    [contentId, type]
  );

  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: `comment_mutation${contentId}`,
    mutationFn: commentMutation,
    onSuccess: async (data) => {
      onSuccess?.(data);
      setIsSuccess(true);

      const matchedQueryKeys = getQueryKeysWithPartialKeys({
        queryClient,
        queryKeys,
      });
      matchedQueryKeys.forEach((queryKey) =>
        handleCommentOptimisticUpdates({
          queryClient,
          queryKey,
          newData: data?.data,
        })
      );
      queryClient.refetchQueries({
        queryKey: getEngagementsQueryKey(type, contentId),
      });
    },
    onError: (err) => {
      setIsError(true);
      handleErrorSnackbar(err, "Couldn't add the comment");
    },
  });
};

export const useNewRepostMutation = ({
  contentId,
  contentType,
  setIsError,
  setIsSuccess,
  onSuccess,
  queryKeys,
}) => {
  const { handleErrorSnackbar, setSnackBarState } = useContext(GlobalContext);

  const rePostMutation = useCallback(
    async ({ contents, urls, images, platforms, channels }) => {
      const response = await signedRequest({
        method: "post",
        path: `/api/0xppl/create_repost`,
        bodyText: JSON.stringify({
          thread: [
            { contents, id: contentId, type: contentType, urls, images },
          ],
          platforms,
          channels,
        }),
      });

      return response.data;
    },
    [contentId, contentType]
  );

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: rePostMutation,
    onSuccess: (data) => {
      onSuccess?.();
      setIsSuccess(true);
      setSnackBarState({
        open: true,
        message: "Successfully reposted",
        type: "success",
      });
      const matchedFeedQueryKeys = getQueryKeysWithPartialKeys({
        queryClient,
        queryKeys: [
          ["my_feed", "global_feed"],
          ["my_feed", "new_feed"],
        ],
      });

      if (matchedFeedQueryKeys?.length)
        matchedFeedQueryKeys.forEach((queryKey) =>
          handleRepostToFeedOptimisticUpdates({
            queryClient,
            queryKey,
            newData: data?.data,
          })
        );

      const matchedActivityGroupQueryKeys = getQueryKeysWithPartialKeys({
        queryClient,
        queryKeys,
      });
      if (matchedActivityGroupQueryKeys?.length)
        matchedActivityGroupQueryKeys.forEach((queryKey) =>
          handleRepostActivityGroupOptimisticUpdates({
            queryClient,
            queryKey,
            newData: data?.data,
          })
        );
    },
    onError: (err) => {
      setIsError(true);
      handleErrorSnackbar(err, "Failed to repost");
    },
  });
};

export const getActivityDetailsByTokenTransferId = async ({
  chainId,
  blockNumber,
  txIdx,
  actor,
}) => {
  const response = await signedRequest({
    method: "get",
    path: `/api/v4/get_activity_id?chain_id=${chainId}&block_number=${blockNumber}&tx_idx=${txIdx}&actor=${actor}`,
  });
  return response.data.data;
};

export const fetchChainIds = async () => {
  const resp = await signedRequest({
    method: "get",
    path: "/api/v4/get_chain_ids",
  });

  return resp.data.data;
};

export const getGlobalNewsForToken = async ({ tokenAddress, chainId }) => {
  const response = await signedRequest({
    method: "get",
    path: `api/v4/get_global_news_for_token?token_address=${tokenAddress}&chain_id=${chainId}`,
  });

  return response.data.data;
};

export const processSeenFeedItems = async (items) => {
  const resp = await signedRequest({
    method: "post",
    path: "/api/v4/process_seen_feed_items",
    bodyText: JSON.stringify({ type_ids: items }),
  });

  return resp.data.data;
};

export const getConnectedPlatforms = async () => {
  const response = await signedRequest({
    method: "get",
    path: "/api/v4/get_connected_social_platforms",
  });

  return response.data.data;
};

export const getConnectFarcaster = async () => {
  const response = await signedRequest({
    method: "get",
    path: "/api/v4/connect_farcaster",
  });

  return response.data.data;
};

export const pollFarcasterConnectStatus = async () => {
  const response = await signedRequest({
    method: "get",
    path: "/api/v4/poll_farcaster_connect_status",
  });
  return response.data.data;
};

export const getFarcasterChannels = async () => {
  const response = await signedRequest({
    method: "get",
    path: "/api/0xppl/get_my_farcaster_channels",
  });
  return response.data.data;
};

export const getConnectTwitter = async () => {
  const response = await signedRequest({
    method: "get",
    path: "/api/twitter/cross_post_request_login",
  });
  return response.data;
};

export const pollTwitterConnectStatus = async () => {
  const response = await signedRequest({
    method: "get",
    path: "/api/twitter/cross_post_callback",
  });

  return response.data.data;
};

export const getHeroNamesFromActivityContext = (acts) => {
  const heroNames = {};
  try {
    acts.forEach((act) => {
      if (act?.fantasy_top !== undefined) {
        const heroCards =
          Object.keys(act.fantasy_top?.hero_name_to_image) || [];
        if (!heroCards.length) return;
        heroCards.forEach((hero) => {
          heroNames[hero] = {
            display_name: hero,
            image_url: act.fantasy_top.hero_name_to_image?.[hero] ?? null,
            link: act.fantasy_top.collection_url,
          };
        });
      }
    });
    if (Object.keys(heroNames).length === 0) return {};
    return heroNames;
  } catch (err) {
    // adding try catch since this is used a lot and data is inconsistent right now
    logSentryEvent(err, {
      extra: {
        acts,
        message: "Error in getHeroNamesFromActivityContext",
      },
    });
    return {};
  }
};

const getOldFeedItem = ({
  item,
  identities,
  profiles,
  chainProfiles,
  nativeTokens,
  identityMentions,
  noAncestorCheck = false,
}) => {
  if (!item) return null;
  let ancestors = [];
  if (!noAncestorCheck && item.ancestors?.length) {
    ancestors = item.ancestors
      ?.filter((a) => a.blob_id !== "")
      .map((a) =>
        getOldFeedItem({
          item: a,
          profiles,
          identities,
          chainProfiles,
          nativeTokens,
          identityMentions,
        })
      );
  }
  const feedChainProfiles = pickBy(chainProfiles, (val, key) => {
    return item.chain_profile_keys?.includes(key);
  });
  const newEngagements = {
    likes: {
      count: item.engagements?.likes?.count || 0,
      is_liked: item.engagements?.likes?.is_liked ?? false,
      likes: item.engagements?.likes?.likes || [],
      selected_emoji_id: item.engagements?.likes?.selected_emoji_id,
    },
    replies: {
      count: item.engagements?.replies?.count || 0,
      involved_identity_ids: [],
      threads:
        item.engagements?.replies?.threads?.map((t) =>
          getOldFeedItem({
            item: t,
            profiles,
            chainProfiles,
            nativeTokens,
            identityMentions,
            noAncestorCheck: true,
          })
        ) || [],
    },
    reposts: {
      count: item.engagements?.reposts?.count || 0,
      threads:
        item.engagements?.reposts?.threads?.map((t) =>
          getOldFeedItem({
            item: t,
            profiles,
            chainProfiles,
            nativeTokens,
            identityMentions,
            noAncestorCheck: true,
          })
        ) || [],
    },
    bookmarks: {
      count:
        item.engagements?.bookmarks?.count ??
        item.engagements?.bookmarks?.is_bookmarked
          ? 1
          : 0,
      is_bookmarked: item.engagements?.bookmarks?.is_bookmarked ?? false,
      bookmarks: item.engagements?.bookmarks?.bookmarks || [],
    },
  };
  const hasFantasyTopContext =
    item.activity_contexts?.some((act) => act?.fantasy_top !== undefined) ??
    false;
  let newItem = {
    ...item,
    ancestors: ancestors,
    actor_addr: item.actor?.address,
    address_pairwise_summary: {},
    chain_profiles: feedChainProfiles,
    engagements: newEngagements,
    group_id: item.blob_id?.split(":")[0],
    identities: identities,
    hero_names: !!hasFantasyTopContext
      ? getHeroNamesFromActivityContext(item.activity_contexts)
      : {},
    // method_ids: {},
    native_tokens_dict: nativeTokens,
    num_protocols: item.num_protocols || 0,
    num_wallets: item.num_wallets || 0,
    num_txns: item.num_txns || 0,
    profiles: profiles,
    profile_keys: Object.keys(profiles || {}),
    score: 0,
    id: item.blob_id?.split(":")[2],
    type: item.blob_id?.split(":")[1],
  };
  if (newItem.type === "post") {
    newItem = {
      ...newItem,
      post: {
        id: item.blob_id.split(":")[2],
        timestamp: item.timestamp,
        // creator_identity: item?.actor?.address,
        creator_identity: parseInt(item.blob_id.split(":")[0]?.split("_")[1]),
        contents: item.description_text,
        url_previews: item.url_previews || [],
        engagements: newEngagements,
        images:
          item.images?.map((i) => ({
            ...i,
            src: i.downsampled_image,
            scaled_src: i.image,
          })) || [],
        cross_post_urls: item.cross_post_urls || {},
        mentioned_identity_ids: Object.keys(item.identityMentions || {}),
        mentioned_addresses: Object.keys(profiles || {}),
      },
      reposted:
        item.reposted?.blob_id !== ""
          ? getOldFeedItem({
              item: item.reposted,
              profiles,
              chainProfiles,
              nativeTokens,
              identityMentions,
              noAncestorCheck: true,
            })
          : null,
    };
  } else {
    const newMedia = item.media?.map((m) => ({ media: item.media })) || [];
    const activityContexts =
      item?.activity_contexts?.map((a) => ({
        activity_contexts: a,
      })) || [];

    const signedActivities =
      item.multisig_signatories?.map((s) => ({
        addresses_involved: { mulitisig_signatories: s }, // mulitisig is a typo here which is why it's not multisig
      })) || [];
    newItem = {
      ...newItem,
      activities: [...signedActivities, ...newMedia, ...activityContexts],
      summary: {
        summary: item.description_text,
        feed_summary: item.description_text,
        native_tokens: nativeTokens,
      },
    };
  }
  return newItem;
};

const transformEntitiesForNewFeedItem = ({
  profiles,
  chain_profiles,
  native_tokens_dict,
  identity_mentions,
}) => {
  const transformedProfiles = {},
    transformedChainProfiles = {},
    transformedNativeTokens = {},
    transformedIdentitiyMentions = {};
  for (let p in profiles) {
    const profile = profiles[p];
    transformedProfiles[p] = {
      ...profile,
      profiles_dict: true,
      address: p,
      address_chain: profile.chain_id,
      link: profile.link,
      ...(profile.chain_id !== "_Any"
        ? {
            is_token: profile.protocol_link == null,
            token_details: {
              symbol: profile.symbol,
              name: profile.display_name,
              logo: profile.display_picture,
            },
            ...(profile.protocol_link
              ? {
                  protocol_details: {
                    link: profile.protocol_link,
                    identifier: profile.identifier,
                    image: profile.protocol_image,
                  },
                }
              : {}),
          }
        : {}),
    };
  }
  for (let p in chain_profiles) {
    transformedChainProfiles[p] = {
      ...chain_profiles[p],
      address: p,
      symbol: p,
      logo_uri: chain_profiles[p].logo,
      address_chain: chain_profiles[p].display_name,
      token_details: {
        chain_profiles_dict: true,
        name: chain_profiles[p].display_name,
        chain_id: p,
        link: "/" + p,
      },
      link: "/" + p,
      logo: chain_profiles[p].logo,
    };
  }
  for (let n in native_tokens_dict) {
    const token = native_tokens_dict[n];
    transformedNativeTokens[n] = {
      ...token,
      display_name: token.name,
      display_picture: token.logo,
      logo_uri: token.logo,
      symbol: n,
      token_details: {
        native_tokens_dict: true,
        name: token.name,
        symbol: n,
        logo: token.logo,
        link: token.link,
      },
    };
  }
  for (let i in identity_mentions) {
    const address = identity_mentions[i];
    transformedIdentitiyMentions[i] = transformedProfiles[address];
  }
  return {
    transformedProfiles,
    transformedChainProfiles,
    transformedNativeTokens,
    transformedIdentitiyMentions,
  };
};

export const transformNewFeedItem = ({ feedResponse }) => {
  const {
    items,
    profiles,
    identities,
    chain_profiles,
    native_tokens_dict,
    identity_mentions,
    cursor,
  } = feedResponse?.data?.data;
  const {
    transformedProfiles,
    transformedChainProfiles,
    transformedNativeTokens,
    transformedIdentitiyMentions,
  } = transformEntitiesForNewFeedItem({
    profiles,
    chain_profiles,
    native_tokens_dict,
    identity_mentions,
  });
  const transformedData = [];
  items?.forEach((item) => {
    const oldFeedItem = getOldFeedItem({
      item,
      identities,
      profiles: transformedProfiles,
      chainProfiles: transformedChainProfiles,
      nativeTokens: transformedNativeTokens,
      identityMentions: transformedIdentitiyMentions,
    });
    const threads = item?.threads?.map((item) => {
      return getOldFeedItem({
        item,
        identities,
        profiles: transformedProfiles,
        chainProfiles: transformedChainProfiles,
        nativeTokens: transformedNativeTokens,
        identityMentions: transformedIdentitiyMentions,
      });
    });
    oldFeedItem.threads = threads;
    transformedData.push(oldFeedItem);
  });

  return { transformedData, cursor };
};

export const transformSingleNewFeedItem = ({ feedResponse }) => {
  const {
    item,
    profiles,
    chain_profiles,
    native_tokens_dict,
    identity_mentions,
    identities,
  } = feedResponse?.data?.data;
  const {
    transformedProfiles,
    transformedChainProfiles,
    transformedNativeTokens,
    transformedIdentitiyMentions,
  } = transformEntitiesForNewFeedItem({
    profiles,
    chain_profiles,
    native_tokens_dict,
    identity_mentions,
  });

  const oldFeedItem = getOldFeedItem({
    item,
    identities: identities ?? {},
    profiles: transformedProfiles,
    chainProfiles: transformedChainProfiles,
    nativeTokens: transformedNativeTokens,
    identityMentions: transformedIdentitiyMentions,
  });
  return oldFeedItem;
};

export const getNewFeed = async ({ pageParam, filter, slowPath = false }) => {
  let endpoint = `/api/v4/get_feed_v1?slow_path=${slowPath}`;
  if (filter === "posts") {
    endpoint += "&is_onchain=false&is_offchain=true";
  } else if (filter === "transactions") {
    endpoint += "&is_onchain=true&is_offchain=false";
  }
  if (pageParam)
    endpoint += "&cursor=" + pageParam.timestamp_window + ":" + pageParam.score;

  const feedResponse = await signedRequest({
    method: "get",
    path: endpoint,
  });
  checkIfDuplicateItems({ feedResponse });

  const { transformedData, cursor } = transformNewFeedItem({ feedResponse });
  return { data: transformedData, cursor };
};

const checkIfDuplicateItems = ({ feedResponse }) => {
  try {
    const items = feedResponse?.data?.data?.items;
    if (!items) return false;
    const itemIds = items.map((i) => i.blob_id);
    const uniqueItemIds = [...new Set(itemIds)];
    //check if duplicate, if yes then get the id of duplicate one's and add sentry
    if (itemIds.length !== uniqueItemIds.length) {
      const duplicateIds = itemIds.filter(
        (id, index) => itemIds.indexOf(id) !== index
      );
      logSentryEvent(new Error("Duplicate feed items"), {
        extra: {
          duplicateIds,
        },
      });
      return true;
    }
  } catch (e) {}
};

export const getWelcomePosts = async () => {
  const resp = await signedRequest({
    method: "get",
    path: "/api/v4/get_welcome_posts_feed?page_size=10",
  });
  return resp.data.data[0];
};

export const getCommentSuggestionsOnPnlInFeed = async () => {
  const data = await signedRequest({
    method: "get",
    path: "/api/v4/get_comment_suggestions_on_pnl_in_feed",
  });

  return data?.data;
};

export const getTopPnlForFeed = async () => {
  const data = await signedRequest({
    method: "post",
    path: "/api/v4/get_top_pnl_activities",
  });
  return data?.data.data;
};

export const getTopPnlForIdentity = async ({ identity }) => {
  const data = await signedRequest({
    method: "post",
    path: "/api/0xppl/get_top_pnl_for_identity",
    bodyText: JSON.stringify({ identity_id: identity }),
  });
  return data?.data.data;
};

export const getBacklink = async ({ id }) => {
  const res = await signedRequest({
    method: "post",
    path: `/api/0xppl/get_backlink_url`,
    bodyText: JSON.stringify({ id }),
  });
  return res?.data?.data?.redirect_url ?? null;
};

export const getTrendingContractInteractions = async () => {
  const response = await signedRequest({
    method: "post",
    path: "/api/v4/get_trending_contract_interactions",
  });
  return response?.data.data;
};

export const getPendingPosts = async () => {
  const response = await signedRequest({
    method: "post",
    path: "api/0xppl/get_pending_posts",
  });
  return response?.data.data;
};

export const deletePendingPost = async ({ id }) => {
  const response = await signedRequest({
    method: "post",
    path: "api/0xppl/delete_pending_post",
    bodyText: JSON.stringify({ pending_post_id: id }),
  });
  return response?.data.data;
};

export const useGetPendingPosts = () => {
  const { isUserLoggedIn } = useContext(AuthContext);
  return useQuery({
    queryKey: ["pendingPosts"],
    queryFn: getPendingPosts,
    retry: 3,
    retryOnMount: false,
    staleTime: ONE_DAY,
    enabled: isUserLoggedIn,
  });
};

export const usePendingPostMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deletePendingPost,
    onMutate: async ({ id }) => {
      await queryClient.cancelQueries(["pendingPosts"]);
      const previousPosts = queryClient.getQueryData(["pendingPosts"]);
      queryClient.setQueryData(["pendingPosts"], (old) => {
        return produce(old, (draft) => {
          draft.scheduled_threads = draft.scheduled_threads.filter(
            (post) => post.id !== id
          );
          draft.draft_threads = draft.draft_threads.filter(
            (post) => post.id !== id
          );
        });
      });
      return { previousPosts };
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["pendingPosts"] });
    },
  });
};

export const getEngagements = async ({ id, type }) => {
  if (!id) {
    return Promise.resolve({});
  }

  const response = await signedRequest({
    method: "get",
    path: `/api/0xppl/engagements?id=${id}&type=${type === "welcomePost" ? "welcome" : type}`,
  });
  return response?.data.data;
};

export const getTrendingTokenInNetworkDetails = async ({
  type,
  chain,
  address,
}) => {
  const response = await signedRequest({
    method: "get",
    path: `/api/v4/get_trending_token_in_network_details?type=${type}&chain=${chain}&address=${address}`,
  });
  return response?.data.data;
};

export const getPolyMarketStatus = async (conditionIds) => {
  //remove nulls
  const updatedIds = conditionIds.filter((c) => c !== null && c !== undefined);
  const response = await signedRequest({
    method: "post",
    path: `/api/v4/get_polymarket_market_status`,
    bodyText: JSON.stringify({
      condition_ids: updatedIds,
    }),
  });
  return response?.data.data;
};

export const usePolyMarketStatus = (conditionIds) => {
  return useQuery({
    queryKey: ["polyMarketStatus", conditionIds],
    queryFn: () => {
      return getPolyMarketStatus(conditionIds);
    },
    staleTime: ONE_DAY,
    keepPreviousData: true,
    enabled: conditionIds?.length > 0,
  });
};

export const useGetGroupActivity = ({ id, type, enabled = true }) => {
  return useQuery({
    queryKey: [QueryKeyConstants.GROUPED_ACTIVITY, id, type],
    queryFn: () => getGroupedActivity({ id: id, type: type }),
    staleTime: ONE_DAY,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: enabled && !!(id && type),
  });
};

export const useGetGroupActivityFromBlob = ({ id, type }) => {
  return useQuery({
    queryKey: [QueryKeyConstants.GROUPED_ACTIVITY_FROM_BLOB, id, type],
    queryFn: () => getGroupedActivityFromBlob({ id: id, type: type }),
    enabled: !!(id && type),
    staleTime: ONE_DAY,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetEngagements = ({ id, type }) => {
  return useQuery({
    queryKey: [QueryKeyConstants.ENGAGEMENTS, id?.toString(), type],
    queryFn: () => getEngagements({ id, type }),
    staleTime: ONE_DAY,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!(id && type),
  });
};

export const useGetCommentsForPost = ({ id, type, enabled }) => {
  return useQuery({
    queryKey: [QueryKeyConstants.COMMENTS, id?.toString(), type],
    queryFn: () => getEngagements({ id, type }),
    staleTime: ONE_DAY,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: enabled && !!(id && type),
  });
};

export const useFetchFeed = ({
  isBookmark = false,
  isNotifications = false,
  activeTab = "global",
  path,
  filter,
  enabled = true,
} = {}) => {
  const queryClient = useQueryClient();
  const pageSize = ["trending", "trending_timeline"].includes(activeTab)
    ? 20
    : 10;
  const searchParams = new URLSearchParams({
    page_size: pageSize,
  });
  const { featureFlags } = useContext(GlobalContext);
  const { isUserLoggedIn } = useContext(AuthContext);
  const location = useLocation();
  let endpoint;

  if (isBookmark) {
    endpoint = "/api/0xppl/my_bookmarks";
  } else if (isNotifications) {
    endpoint = "/api/fcm/get_notifications";
  } else {
    const baseApiUrl = "/api/v4/";
    const trendingTimelineUrl = `${baseApiUrl}get_trending_items?${searchParams.toString()}`;
    const trendingFeed = `${baseApiUrl}get_trending_cards?${searchParams.toString()}&ignore_swaps=true`;

    const paths = {
      trending_timeline: trendingTimelineUrl,
      trending: trendingFeed,
    };

    endpoint = paths[activeTab];
  }
  if (path) {
    endpoint = path;
  }

  const getSlowPathFeed = useCallback(
    async ({ pageParam, filter }) => {
      if (filter === "posts") return;
      try {
        const pnlFeedPage = await getNewFeed({
          pageParam,
          filter,
          slowPath: true,
        });
        pnlFeedPage?.data?.forEach((item) => {
          if (item.user_token_pnl) {
            queryClient.setQueryData(
              [QueryKeyConstants.SLOW_FEED_ITEM, item.blob_id],
              () => {
                return {
                  ...item,
                  user_token_pnl: item.user_token_pnl,
                };
              }
            );
          }
        });
      } catch (err) {
        console.error({ err });
      }
    },
    [queryClient]
  );

  const getActivities = useCallback(
    async ({ pageParam = endpoint, queryClient }) => {
      if (activeTab === "new_feed") {
        getSlowPathFeed({ pageParam, filter, queryClient });
        const response = await getNewFeed({ pageParam, filter });
        return response;
      }
      if (
        activeTab === "global_feed" &&
        !isBookmark &&
        !isNotifications &&
        !path
      ) {
        const response = await getFeedData({ pageParam });
        return response;
      }
      const res = await signedRequest({
        method: "get",
        path: pageParam,
      });
      if (res.data?.metadata?.version === 1) {
        const { transformedData, cursor } = transformNewFeedItem({
          feedResponse: res,
        });
        let cursorEndpoint =
          cursor == null || cursor.timestamp_window == null
            ? null
            : endpoint +
              "&cursor=" +
              cursor.timestamp_window +
              ":" +
              cursor.score;
        return { data: transformedData, cursor: cursorEndpoint };
      }
      return res.data;
    },
    [
      filter,
      activeTab,
      endpoint,
      path,
      isBookmark,
      isNotifications,
      getSlowPathFeed,
    ]
  );

  const {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: isBookmark
      ? ["my_bookmark", location.key]
      : isNotifications
        ? ["notifications", location.key]
        : ["my_feed", activeTab, path, filter],
    queryFn: getActivities,
    retry: 2,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    getNextPageParam: (lastPage) => {
      if (activeTab === "global_feed") {
        return lastPage?.links;
      } else if (activeTab === "new_feed") {
        return lastPage?.cursor;
      }
      if (lastPage?.cursor) {
        return lastPage?.cursor;
      }
      if (isNotifications && lastPage?.data?.activities.length > 0) {
        const cursor = lastPage?.data?.cursor;
        return `/api/fcm/get_notifications?cursor=${cursor}`;
      }
      return lastPage?.links?.next;
    },
    enabled,
  });
  const insertHorizontalScrolls = (data) => {
    if (!data || !data.pages) return;
    const isFeed = activeTab === "new_feed";
    const pageIndex = data?.pages?.findIndex((page) => page?.data?.length > 3);
    const pageHasItem = (page, item) => {
      return page?.data?.findIndex((i) => i.type === item) !== -1;
    };

    if (pageIndex !== -1 && isFeed && filter === "") {
      const page = data.pages[pageIndex];
      if (!pageHasItem(page, "trending_contracts_index") && isUserLoggedIn) {
        data.pages[pageIndex].data.splice(1, 0, {
          type: "trending_contracts_index",
        });
      }
      if (!pageHasItem(page, "welcomePosts") && isUserLoggedIn) {
        data.pages[pageIndex].data.splice(3, 0, { type: "welcomePosts" });
      }
      if (
        !pageHasItem(page, "trending_feed_index") &&
        featureFlags.show_trending_contracts
      ) {
        data.pages[pageIndex].data.splice(6, 0, {
          type: "trending_feed_index",
        });
      }
      if (!pageHasItem(page, "follow_suggestion_index") && isUserLoggedIn) {
        data.pages[pageIndex].data.splice(10, 0, {
          type: "follow_suggestion_index",
        });
      }
      if (!pageHasItem(page, "top_pnl_index")) {
        data.pages[pageIndex].data.splice(8, 0, { type: "top_pnl_index" });
      }
    }
    return data;
  };

  const flattenedPages = useMemo(() => {
    const flattenedPages = data?.pages?.flatMap((page) => page?.data);
    return flattenedPages;
  }, [data?.pages]);
  return {
    flattenedFeedData: flattenedPages,
    data: insertHorizontalScrolls(data),
    isLoading,
    isSuccess,
    isError,
    refetch,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
};
