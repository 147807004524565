import defaultProfileImage from "assets/webp/profile_picture.webp";
import { getLocallyHostedImage } from "utils/misc";
import nftFallback from "assets/svg/feed/nft_fallback.svg";
import smallNftFallback from "assets/svg/feed/small_nft_fallback.svg";
import avatarFallback from "assets/svg/feed/avatar_fallback.svg";
import smallAvatarFallback from "assets/svg/feed/small_avatar_fallback.svg";
import tokenFallback from "assets/svg/feed/token_fallback.svg";
import smallTokenFallback from "assets/svg/feed/small_token_fallback.svg";
import contractFallback from "assets/svg/feed/contract_fallback.svg";
import smallContractFallback from "assets/svg/feed/small_contract_fallback.svg";
import { forwardRef, useMemo, useState } from "react";

export const IMAGE_TYPES = {
  AVATAR: "avatar",
  SMALL_AVATAR: "small_avatar",
  CONTRACT: "contract",
  SMALL_CONTRACT: "small_contract",
  TOKEN: "token",
  SMALL_TOKEN: "small_token",
  NEW_FALLBACK: "new_fallback",
  NFT: "nft",
  SMALL_NFT: "small_nft",
  CHAIN: "chain",
  NO_FALLBACK: "no_fallback",
};

export const getDefaultImage = ({ imageType }) => {
  switch (imageType) {
    case IMAGE_TYPES.AVATAR:
      return avatarFallback;
    case IMAGE_TYPES.SMALL_AVATAR:
      return smallAvatarFallback;
    case IMAGE_TYPES.NEW_FALLBACK:
      return avatarFallback;
    case IMAGE_TYPES.NFT:
      return nftFallback;
    case IMAGE_TYPES.SMALL_NFT:
      return smallNftFallback;
    case IMAGE_TYPES.CONTRACT:
      return contractFallback;
    case IMAGE_TYPES.SMALL_CONTRACT:
      return smallContractFallback;
    case IMAGE_TYPES.TOKEN:
      return tokenFallback;
    case IMAGE_TYPES.SMALL_TOKEN:
      return smallTokenFallback;
    case IMAGE_TYPES.CHAIN:
      return contractFallback;
    case IMAGE_TYPES.NO_FALLBACK:
      return null;
    default:
      return defaultProfileImage;
  }
};

const Image = forwardRef(
  (
    {
      src,
      alt,
      imageType = IMAGE_TYPES.AVATAR,
      className,
      fallbackClassName,
      style,
      onLoad = () => {},
      onErrorCallback = () => {},
      ...props
    },
    ref
  ) => {
    const [disableImage, setDisableImage] = useState(false);
    if (src === "assets/png/unknown_token_logo.png") {
      src = "";
    }
    const source = useMemo(() => {
      const transformedSrc = getLocallyHostedImage(src);
      if (transformedSrc == null) {
        if (imageType === IMAGE_TYPES.NO_FALLBACK) return null;
        onErrorCallback();
        return getDefaultImage({ imageType });
      }
      return transformedSrc;
    }, [src, imageType, onErrorCallback]);

    const onError = (e) => {
      console.warn(`${imageType} not found`, { src });
      e.stopPropagation();
      if (imageType === IMAGE_TYPES.NO_FALLBACK) {
        setDisableImage(true);
      } else {
        e.target.src = getDefaultImage({ imageType });
      }
      onErrorCallback();
      if (fallbackClassName) {
        e.target.classList.add(fallbackClassName);
      }
    };

    return (
      !disableImage &&
      source && (
        <img
          ref={ref}
          src={source}
          alt={alt}
          {...props}
          style={style}
          className={className}
          onError={onError}
          onLoad={onLoad}
        />
      )
    );
    //   uncomment the below line for testing
    // return <img src={`${source}break`} alt={alt} {...props} onError={onError} />;
  }
);

export default Image;
