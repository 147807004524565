import React, {
  createContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from "react";
import FullPageLoader from "shared/FullPageLoader/FullPageLoader";
import Pusher from "pusher-js";
import { getSupportedChains, getStaticConfigs } from "api/profile.api";
import { v4 as uuidv4 } from "uuid";
import { CHAINS } from "utils/constants";
import { lowercaseObjectKeys } from "utils/misc";

export const GlobalContext = createContext({});

const GlobalContextProvider = (props) => {
  const [loading] = useState(false);
  const [supportedChainsDict, setSupportedChainsDict] = useState(null);
  const [supportedChainsLowerCaseDict, setSupportedChainsLowerCaseDict] =
    useState(null);
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    message: "",
    type: "",
    vertical: "bottom",
    horizontal: "left",
  });
  const [ribbonData, setRibbonData] = useState(null);
  const [featureFlags, setFeatureFlags] = useState({
    show_new_feed: true,
    show_trending_tokens: false,
    is_lens_cross_post_enabled: false,
  });
  const [isEngagementsRightPanelOpen, setIsEngagementsRightPanelOpen] =
    useState(false);
  const updateEngagementsRightPanel = (value) => {
    setIsEngagementsRightPanelOpen(value);
  };
  const userPreferencesRef = useRef({});
  const activeNotifications = useRef({});
  const currentActiveTransaction = useRef(null);
  const [isEditContractPopupShown, setIsEditContractPopupShown] =
    useState(false);
  const editContractPopupDetails = useRef(null);
  // setting this opens up user x token transfer details
  const [userTokenTransferDetails, setUserTokenTransferDetails] = useState({});

  const pusher = useMemo(
    () =>
      new Pusher("967f0dcec1810d200c24", {
        cluster: "us2",
      }),
    []
  );

  const getPusherChannel = () => {
    let pusherChannelId = localStorage.getItem("pusherChannelSessionAddressId");
    const userAddress = localStorage.getItem("userAddress");
    if (!pusherChannelId || pusherChannelId === "null") {
      pusherChannelId = userAddress ?? uuidv4();
      localStorage.setItem("pusherChannelSessionAddressId", pusherChannelId);
    } else if (
      userAddress !== pusherChannelId &&
      userAddress !== "null" &&
      userAddress !== null
    ) {
      pusherChannelId = userAddress;
      localStorage.setItem("pusherChannelSessionAddressId", pusherChannelId);
    }
    return pusher.subscribe(pusherChannelId);
  };

  const [channel] = useState(getPusherChannel());

  const handleErrorSnackbar = (
    err,
    customMessage = null,
    showFullError = true,
    position = { vertical: "bottom", horizontal: "left" },
    duration = 4000
  ) => {
    if (err === null || err.message !== "canceled")
      setSnackBarState({
        open: true,
        message: customMessage?.length
          ? err
            ? `${customMessage} ${showFullError ? `Error: ${err.message}` : ``}`
            : customMessage
          : err.message,
        type: "error",
        vertical: position.vertical,
        horizontal: position.horizontal,
        duration,
      });
  };

  const handleSuccessSnackbar = (
    customMessage = null,
    position = { vertical: "bottom", horizontal: "left" }
  ) => {
    if (!position.vertical) position.vertical = "bottom";
    if (!position.horizontal) position.horizontal = "left";
    setSnackBarState({
      open: true,
      message: customMessage,
      type: "success",
      vertical: position.vertical,
      horizontal: position.horizontal,
    });
  };

  useEffect(() => {
    //get supported chains from local storage first and set
    const rawSupportedChains = localStorage.getItem("supportedChains");
    if (rawSupportedChains) {
      const supportedChains = JSON.parse(rawSupportedChains);
      setSupportedChainsDict(supportedChains);
      setSupportedChainsLowerCaseDict(lowercaseObjectKeys(supportedChains));
    }
    getSupportedChains().then((parsedSupportedChains) => {
      setSupportedChainsDict(parsedSupportedChains);
      setSupportedChainsLowerCaseDict(
        lowercaseObjectKeys(parsedSupportedChains)
      );
      //set this in local storage as stringified object
      localStorage.setItem(
        "supportedChains",
        JSON.stringify(parsedSupportedChains)
      );
    });
    const rawFeatureFlags = localStorage.getItem("featureFlags");
    if (rawFeatureFlags) {
      setFeatureFlags({
        ...JSON.parse(rawFeatureFlags),
      });
    }
    getStaticConfigs().then((res) => {
      localStorage.setItem("featureFlags", JSON.stringify(res));
      setFeatureFlags(res);
    });

    const rawUserPreferences = localStorage.getItem("userPreferences");
    if (rawUserPreferences) {
      userPreferencesRef.current = JSON.parse(rawUserPreferences);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSupportedChainsListForRoutes = useCallback(() => {
    if (!!supportedChainsDict) {
      const chains = Object.keys(supportedChainsDict);
      return "(" + chains.join("|") + ")";
    }
    return CHAINS;
  }, [supportedChainsDict]);

  const [windowWidth, setWindowWidth] = useState(800);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getUserPreference = (key) => {
    return userPreferencesRef?.current?.[key];
  };

  const setUserPreference = (key, value) => {
    userPreferencesRef?.current
      ? (userPreferencesRef.current[key] = value)
      : (userPreferencesRef.current = { [key]: value });
    localStorage.setItem(
      "userPreferences",
      JSON.stringify(userPreferencesRef.current)
    );
  };

  return (
    <GlobalContext.Provider
      value={{
        snackBarState,
        setSnackBarState,
        handleErrorSnackbar,
        channel,
        pusher,
        handleSuccessSnackbar,
        ribbonData,
        setRibbonData,
        supportedChainsDict,
        supportedChainsLowerCaseDict,
        getSupportedChainsListForRoutes,
        windowWidth,
        featureFlags,
        getUserPreference,
        setUserPreference,
        activeNotifications,
        editContractPopupDetails,
        isEditContractPopupShown,
        setIsEditContractPopupShown,
        userTokenTransferDetails,
        setUserTokenTransferDetails,
        currentActiveTransaction,
        isEngagementsRightPanelOpen,
        updateEngagementsRightPanel,
      }}>
      {loading ? <FullPageLoader /> : props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
