import { useHistory } from "react-router-dom";
import { useEffect } from "react";

let prevLocation = "prevLocation";
const scrollIndex = new Map();
const heightIndex = new Map();
const feedElementSelector = "#root .App .feed";

const getKeyFromLocation = (location) => {
  return location.key || location.pathname + location.search + location.hash;
};
let delayedScrollId = null;
const keepTryingToScrollTillSuccess = (toScroll, toHeight) => {
  let counter = 0;
  const optimisticallyUpdateScroll = () => {
    const feedElement = document.querySelector(feedElementSelector);
    if (feedElement) {
      feedElement.style.minHeight = toHeight + "px";
    }
    window.scrollTo({
      top: toScroll,
      left: 0,
    });
    requestAnimationFrame(() => {
      if (Math.abs(window.scrollY - toScroll) > 100 && counter < 4) {
        counter++;
        delayedScrollId = setTimeout(optimisticallyUpdateScroll, 50);
      }
    });
  };
  optimisticallyUpdateScroll();
  requestAnimationFrame(optimisticallyUpdateScroll);
};

const ScrollHandler = () => {
  const history = useHistory();
  prevLocation = getKeyFromLocation(history.location);
  const onRouteChange = (e) => {
    const ignoreScrollPages = [
      "/activity_group",
      "/post",
      "/sol_activity_group",
    ];
    const isDetailsPage = ignoreScrollPages.some((page) =>
      e.pathname.includes(page)
    );
    const key = getKeyFromLocation(e);
    clearTimeout(delayedScrollId);
    const toScroll = scrollIndex.get(key) || 0;
    const toHeight = heightIndex.get(key) || "auto";
    const feedElement = document.querySelector(feedElementSelector);
    if (feedElement && !isDetailsPage) {
      heightIndex.set(prevLocation, feedElement.offsetHeight);
    }
    scrollIndex.set(prevLocation, window.scrollY);
    if (!isDetailsPage) {
      keepTryingToScrollTillSuccess(toScroll, toHeight);
    }
    prevLocation = key;
  };
  useEffect(() => {
    return history.listen(onRouteChange);
  }, [history]);
  return null;
};

export default ScrollHandler;
